/* Base style for the searchBar */
.searchBar {
    border-radius: 15px;
    width: 700px;
    max-width: 85%;
    padding: 10px;
    background-color: #dbdbdb;
    border: 2px solid #dbdbdb; /* Set the initial border color */
    color: black;
    text-align: center;
    font-size: 18px; /* Increase the font size */
    transition: border-color 0.3s; /* Add a transition for border color change */
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  /* Center the placeholder text and increase its font size */
  .searchBar::placeholder {
    text-align: center;
    font-size: 18px; /* Adjust the font size as needed */
  }
  
  /* Add border color when the input is focused (pressed) */
  .searchBar:focus {
    border-color: #eb5e28; /* Change to the desired border color */
    outline: none; /* Remove the default outline */
  }

  .bottom{
    margin-top: 10px;
  }

  h1{
    font-size: medium;
    margin-bottom: 15px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    margin: 10px;
  }

  .para{
    padding: 10px;
    background-color: #112a38;
    border-radius: 15px;
    margin: 20px;
  }

  .moonLoader{
    margin: 0 auto;
  }
  