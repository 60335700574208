
.celebContainer{

    border: 4px;
    border-color: black;
    padding: 5px;
}

/* Base styles for .celebCard */
.celebCard {
    border: 4px;
    border-color: black;
    border-radius: 10px;
    margin: 10px;
    padding: 20px; /* Reduce padding for smaller screens */
    display: flex;
    justify-content: space-between;
    background-color: #284b63;
    color: white;
    width:  -webkit-fill-available;
    text-align: left;
    align-items: center;
}

.celebCard h3 {
    width: 15%;
}

.celebCard p {
    width: 20%;
}

.sMediaContainer {
    display: flex;
    flex-direction: row;
}

.insta{
    margin-left: 5px;
    vertical-align: middle;
}

.insta:hover{
    color: #eb5e28;
}

.source{
    height: auto;
}


/* Style for .celebCard on smaller screens (e.g., mobile) */
@media (max-width: 768px) {
    .celebCard {
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        text-align: center;
    }
    
    .celebCard h3,
    .celebCard p {
        width: auto; /* Allow text to wrap */
        margin: 5px; /* Adjust margins for spacing */
    }
}

/* Adjust container padding for smaller screens */
@media (max-width: 768px) {
    .celebContainer {
        padding: 20px;
    }
}

