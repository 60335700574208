.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #284b63;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
  }
  
  .modal-buttons {
    margin-top: 20px;
  }
  
  .modal button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #eb5e28;
  }
  