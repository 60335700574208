input{
  border-radius: 15px;
  width: 700px;
  max-width: 85%;
  padding: 10px;
  background-color: #dbdbdb;
  border: 2px solid #dbdbdb; /* Set the initial border color */
  color: black;
  text-align: center;
  font-size: 18px; /* Increase the font size */
  transition: border-color 0.3s; /* Add a transition for border color change */
}

#collection{
  border-radius: 15px;
  width: 300px;
  max-width: 85%;
  padding: 10px;
  background-color: #dbdbdb;
  border: 2px solid #dbdbdb; /* Set the initial border color */
  color: black;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px; /* Increase the font size */
  transition: border-color 0.3s; /* Add a transition for border color change */
}

.formContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center vertically */
}

label{
  margin:10px;
}

.submitButton{
  background-color: #eb5e28;
  flex-grow: 1;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  font-size: medium;
  text-decoration: none;
  width: 500px;
  margin-top:30px;
}

a:hover{
  color: #f4f9e9;
}

@media (max-width: 768px) {
  input{
    width: 400px;
  }

  .submitButton{
    width: 300px;
  }
}