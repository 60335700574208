@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;300;400;500;600;700&display=swap');

*{
    font-family: 'Poppins', sans-serif;
}

a:link, a:visited {
  text-decoration: none;
}

.heading{
    padding: 30px;
    font-size:xx-large;
    font-family: 'Poppins', sans-serif;
    color: #f4f9e9;
    display: flex;
  justify-content: space-between;
  align-items: stretch; /* Make buttons fill the full height */
}


.logo {
    text-align: center;
  }
  
  .rightButtons {
    margin-left: auto; /* Pushes the button to the right */
    display: flex;
    align-items: stretch; /* Make buttons fill the full height */
    
  }

  .uCL{
    background-color: #eb5e28;
    flex-grow: 1;
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;
    border: none;
    font-weight: 600;
    font-size: medium;
    text-decoration: none;
    margin: auto;
    margin-right: 5px;
  }

  a:hover{
    color: #f4f9e9;
  }

  .logoWord:hover{
    color: #eb5e28;
  }

  button{
    flex-grow: 1;
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;
    border: none;
    font-weight: 600;
    font-size: medium;
    margin-right: 8px;
  }

  .hidden{
    display: none;
  }

/* CSS for the hamburger menu icon */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #eb5e28;
  margin: 4px 0;
}



/* Media query for small screens */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .uCL{
    margin: 5px;
  }

  #nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--lightBlue);
    padding: 10px;
    border-radius: 15px;
    margin: 30px;
  }

  #nav-links.active {
    display: flex;
    position: relative;
    top: auto;
  }
}
